import Request from '@/lib/Request'
import config from '@/config'
// const https = new Request('https://cubewebapi.51ylk.com', 3000)
// const httpadmin = new Request('https://cubeadmin.51ylk.com',3000)
const httpadmin = new Request(process.env.VUE_APP_ADMIN)
const http = new Request(process.env.VUE_APP_URL)
// const http = new Request('http://192.168.1.65:19001', 3000)
// 查询我的平面模板
export const selectPlanarListPage = params => {
  // return http.post('/cube_web_api/mine/selectPlanarListPage', params)
  return http.post('/cube-client/user/planar/page', params)
}

// 重命名我的作品
export const rename = params => {
  return http.put('/cube-client/user/planar/rename', {}, params)
  // return http.post('/cube_web_api/planar/rename', params)
}

// 下载
// export const download = 'https://cubewebapi.51ylk.com/cube_web_api/planar/download'

// 删除我的作品
export const cancel = params => {
  // return http.post('/cube_web_api/mine/cancel', params)
  return http.delete('/cube-client/user/planar/delete', params)
}
// 删除我的素材
export const deleteMaterial = params => {
  return http.delete('/cube-client/user/material/delete', params)
}
// 取消收藏
export const cancalFollow = params => {
  return http.post('/cube-client/planar/follow/cancel', params)
}
// 查询我的收藏
export const queryPage = params => {
  return http.post('/cube-client/planar/follow/queryPage', params)
}
// 添加作品、添加收藏
export const add = params => {
  return http.post('/cube_web_api/mine/add', params)
}

// 查询我的素材
export const selectImgListPage = params => {
  // return http.post('/cube_web_api/mine/selectImgListPage', params)
  return http.post('/cube-client/user/material/page', params)
}

// 获取资源库列表（图片库、背景库）
export const selectListPage = params => {
  return http.post('/cube_web_api/img/selectListPage', params)
}

// 我的素材获取腾讯云的token
export const getTmpCredential = params => {
  return http.get('/cube_web_api/cos/getTmpCredential', params)
}

export const addImg = params => {
  // return http.post('/cube_web_api/mine/addImg', params)
  return http.post('/cube-client/user/material/add', params)
}

// 重命名我的素材、背景
export const renameMaterial = params => {
  return http.put('/cube-client/user/material/rename', {}, params)
}
// 模板详情
export const getDetail = params => {
  if (config.admin) {
    // params.status = 0
    return httpadmin.get('/cube-platform/planar/get/one', params)
  } else {
    return http.get('/cube-client/user/planar/get/one', params)
  }
}

// 保存模板
export const saveModel = params => {
  return http.post('/cube_web_api/mine/planar/add', params)
}

// 更新模板
export const updateModel = params => {
  if (config.admin) {
    // params.status = 0
    return httpadmin.post('/cube_admin/planar/save', params)
  } else {
    return http.post('/cube_web_api/mine/planar/update', params)
  }
}

export const getspuitems = params => {
  return http.get('/cube_web_api/product/spuinfo/getspuitems', params)
}

export const getSpuItemByCatalogId = params => {
  return http.post('/cube_web_api/product/spuinfo/getSpuItemByCatalogId', params)
}
/**
 *
 *解析二维码
 */
export const parseQrCode = params => {
  return http.get('/cube_web_api/img/parseQrCode', params)
}
/**
 *
 * 批量修改psdjson数据
 */
export const updateBatch = params => {
  return http.post('/cube_web_api/psdjson/updateBatch', params)
}
